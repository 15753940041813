<template>
  <div>
    <b-row>
      <b-col class="my-1">
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      :items="users"
      :fields="fields"
      :filter="filter"
      :perPage="perPage"
      :pageOptions="pageOptions"
    >
      <template v-slot:cell(groups)="row">
        <ul>
          <li v-for="group in row.item.groups" :key="group.id">
            {{ group.name }}
          </li>
        </ul>
      </template>
    </b-table>
    <b-row>
      <b-col lg="6"></b-col>
      <b-col lg="6" sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import UserService from "../../../services/basedata/UserService";
export default {
  name: "users",
  data() {
    return {
      users: [],
      fields: [
        {
          key: "username",
          sortable: true,
          label: "Username",
        },
        {
          key: "email",
          sortable: false,
          label: "E-Mail",
        },
        {
          key: "is_active",
          sortable: true,
          label: "Aktiv",
        },
        {
          key: "is_staff",
          sortable: true,
          label: "Mitarbeiter",
        },
        {
          key: "is_superuser",
          sortable: true,
          label: "Administrator",
        },
        {
          key: "groups",
          sortable: true,
          label: "Gruppen",
        },
      ],
      filter: null,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
    };
  },
  mounted() {
    UserService.getAllUsers().then((users) => {
      //console.log(users);
      this.users = users;
    });
  },
};
</script>
