import User from "../../models/basedata/User";
import HttpService from "../HttpService";

class UserService {
  async getAllUsers() {
    const users = await HttpService.get<User[]>("basedata/user/");
    //console.log(users);
    return users;
  }
}

export default new UserService();
